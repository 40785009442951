*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
    font-family: "Montserrat";
}

#root {
    display: flex;
    flex-direction: column;
}

.montserrat {
    font-family: "Montserrat";
}

.bg-blue {
    background: #B9D8FF;
}


.list-border {
    border-color: rgb(72, 72, 72);

}

.hr-white {
    color: white;
    height: 5px;
}